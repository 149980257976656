// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artes-js": () => import("./../../../src/pages/artes.js" /* webpackChunkName: "component---src-pages-artes-js" */),
  "component---src-pages-ciencias-js": () => import("./../../../src/pages/ciencias.js" /* webpackChunkName: "component---src-pages-ciencias-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lenguaje-js": () => import("./../../../src/pages/lenguaje.js" /* webpackChunkName: "component---src-pages-lenguaje-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

